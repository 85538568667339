import "./App.css";
import MailMe from "./MailMe";
import { useState } from "react";



function App() {
  const [IP, setIP] = useState("");
  
  fetch("https://api.ipify.org?format=json")
  .then((response) => response.json())
  .then((data) => {
    console.log("IP Address:", data.ip);
    setIP(data.ip);
  });
  return (<div className="App">
    {/* <MailMe IP={IP} /> */}
  </div>
  );
}

export default App;
